import React, {useCallback, useEffect, useState} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import {FormProvider, useForm} from 'react-hook-form';
import HiddenCss from '@material-ui/core/Hidden/HiddenCss';
import {cssText} from '@ohoareau/css-utils';
import {
    AlertBanner,
    ForceTheme,
    LargeTitleText,
    payment_type,
    PaymentTypeEnum, ProductTableNameEnum,
    Row,
    Spinner, useLuniiPage,
    useLuniiSpa,
    useLuniiTranslation,
    useShoppingCart,
} from '../../../ui';
import {CheckoutLayout} from '../../layouts';
import {PaymentRight, PaymentLeft} from '../../molecules';
import {useCheckoutTracking} from '../../../hooks';

const useStyles = makeStyles((theme) => ({
    title: {
        ...cssText(theme, 'standard', 'large_title', undefined, undefined, '#063646'),
    },
    container: {
        paddingBottom: theme.spacing(8),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
        },
    },
    left: {
        position: 'relative',
        '& >*': {
            width: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            position: 'sticky',
            top: 128,
        },
    },
    right: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-end',
        '& >*': {
            width: '100%',
        },
    },
    paymentWait: {
        ...cssText(theme, 'standard', 'title_2'),
        padding: theme.spacing(0, 2),
        textAlign: 'center',
    },
}));

export function PaymentScreen() {
    useCheckoutTracking(true, 'livraison_paiement');
    const classes = useStyles();
    const {t, exists} = useLuniiTranslation();
    const [
        {cart, saveCart},
        {
            saveCart: {loading: loadingSaveCart},
        },
    ] = useShoppingCart();
    const {navigate} = useLuniiSpa();
    const {locale} = useLuniiPage() || {};
    const [cardStatus, setCardStatus] = useState<boolean>(false);
    const [cardRegister, setCardRegister] = useState<boolean>(false);
    const [onEditDeliveryAddress, setOnEditDeliveryAddress] = useState<boolean>(false);
    const [onEditBillingAddress, setOnEditBillingAddress] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [paymentType, setPaymentType] = useState<payment_type | undefined>(undefined);
    const formCreditCardOwner = useForm({mode: 'onChange'});
    const handleAddressUpdate = useCallback(() => {
        saveCart(cart);
    }, [cart]);
    const handleCartError = useCallback(
        (error: any) => {
            navigate('/cart', {state: error});
        },
        [navigate],
    );
    const handlePaymentTypeChoice = (type: payment_type) => {
        if (
            type?.method === PaymentTypeEnum.GIFT_CARD ||
            paymentType?.method === PaymentTypeEnum.GIFT_CARD
        ) {
            saveCart({
                ...cart,
                accountBalance: {
                    use: type?.method === PaymentTypeEnum.GIFT_CARD,
                },
            }); // if use or stop using gift card, we need to do a putcart
        }
        setPaymentType(type);
    };
    const handleGiftCardPartialChange = (status: boolean) => {
        saveCart({
            ...cart,
            accountBalance: {
                use: status,
            },
        });
    };
    useEffect(() => { // reload page every 25mn, so the cart is not deleted.
        const timer = setTimeout(() => {
            typeof window !== 'undefined' && window.location.reload();
        }, 1500000);
        return () => clearTimeout(timer);
    }, []);
    const country = (locale || '').slice(-2);
    const hasHardware = cart?.items?.some((item: any) => item.type === ProductTableNameEnum.Hardware);
    return (
        <CheckoutLayout>
            {hasHardware && exists(`checkout_alert_banner_text_${country}`) && (t(`checkout_alert_banner_text_${country}`) || '').length > 0 && (
                <ForceTheme theme='brand-tertiary-800'>
                    <AlertBanner text={t(`checkout_alert_banner_text_${country}`)}/>
                </ForceTheme>
            )}
            {loading && (
                <Spinner fixed>
                    <div className={classes.paymentWait}>{t('checkout_payment_wait')}</div>
                </Spinner>
            )}
            {loadingSaveCart && (
                <Spinner fixed>
                    <div className={classes.paymentWait}>{t('checkout_payment_update')}</div>
                </Spinner>
            )}
            <FormProvider {...formCreditCardOwner}>
                <Row
                    padding="default-top"
                    alignItems="flex-start"
                    justify="space-between"
                    gridClassName={classes.container}
                >
                    <Grid
                        item
                        container
                        lg={4}
                        md={5}
                        sm={12}
                        className={classes.left}
                        direction="column"
                    >
                        <HiddenCss smDown>
                            <LargeTitleText className={classes.title as any}>
                                {t('checkout_payment_title')}
                            </LargeTitleText>
                        </HiddenCss>
                        <ForceTheme theme="brand-tertiary-50">
                            <PaymentLeft
                                cardStatus={cardStatus}
                                cardRegister={cardRegister}
                                setLoading={setLoading}
                                loading={loading}
                                paymentType={paymentType}
                                onCartError={handleCartError}
                                onEditDeliveryAddress={onEditDeliveryAddress}
                                onEditBillingAddress={onEditBillingAddress}
                            />
                        </ForceTheme>
                    </Grid>
                    <Grid
                        item
                        container
                        lg={7}
                        md={6}
                        sm={12}
                        className={classes.right}
                        direction="column"
                    >
                        <PaymentRight
                            onCardComplete={setCardStatus}
                            onCardRegister={setCardRegister}
                            onAddressUpdate={handleAddressUpdate}
                            onPaymentTypeChoice={handlePaymentTypeChoice}
                            onGiftCardPartialChange={handleGiftCardPartialChange}
                            onEditDeliveryAddress={setOnEditDeliveryAddress}
                            onEditBillingAddress={setOnEditBillingAddress}
                        />
                    </Grid>
                </Row>
            </FormProvider>
        </CheckoutLayout>
    );
}

export default PaymentScreen;
