import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Accordion, AccordionSummary, AccordionDetails} from '@material-ui/core';
import {cssText} from '@ohoareau/css-utils';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import {Trans} from 'react-i18next';
import {cart as cart_type, convertPrice, shipping_method, useLuniiPage, useLuniiTranslation} from '../../../ui';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'transparent !important',
        '&:before': {
            display: 'none',
        },
    },
    rootExpanded: {
        minHeight: '0px !important',
        margin: '0px !important',
    },
    summary: {
        ...cssText(theme, 'standard', 'button_3', undefined, undefined, '#2762E9'),
        textDecoration: 'underline',
        textTransform: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        minHeight: 'unset !important',
        padding: '0px !important',
    },
    summaryContent: {
        flexGrow: 0,
        margin: '0px !important',
    },
    icon: {
        color: '#2762E9',
        padding: '0px !important',
        '& svg': {
            height: '22px !important',
            width: '22px !important',
        },
    },
    summaryExpanded: {
        '& $summaryContent': {
            margin: '0px !important',
        },
        '& $title': {
            display: 'none',
        },
        '& $expandedTitle': {
            display: 'block',
        },
    },
    title: {
        display: 'block',
    },
    expandedTitle: {
      display: 'none',
    },
    detailsRoot: {
        padding: '0px !important',
        margin: '8px 0px 0px 0px !important',
        flexDirection: 'column',
    },
    line: {
        ...cssText(theme, 'standard', 'caption', undefined, undefined, '#1A6F8E'),
    },
    highlight: {
        ...cssText(theme, 'standard', 'button_3', undefined, undefined, '#1A6F8E'),
        textTransform: 'unset',
    },
    lineParagraph: {
      marginTop: 4,
    },
    lineDiscount: {
        color: '#2DAAD7',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 1,
        overflow: 'hidden',
    },
    lineDiscountLimit: {
        ...cssText(theme, 'standard', 'button_3', undefined, undefined, '#2DAAD7'),
        textTransform: 'unset',
    },
    total: {
        marginTop: 4,
        ...cssText(theme, 'standard', 'secondary_body_thick', undefined, undefined, '#1A6F8E'),
    }
}));

export function PaymentDetails({className = '', cart, shippingMethod = null, defaultExpanded = false}: PaymentDetailsProps) {
    const classes = useStyles();
    const {t, exists} = useLuniiTranslation();
    const {locale} = useLuniiPage() || {};
    const {subpriceDetails, currency, appliedDiscountAmount, discount, productsDiscount, accountBalance} = cart;
    const totalPrice = (shippingMethod?.price || 0) + (cart?.leftToPay?.totalWithVat || 0);
    const amountLimit = Math.max(...productsDiscount?.map(productDiscount => productDiscount.amountLimit).filter(x => !!x) || [], (discount?.amountLimit || 0))
    const showAmountLimit = amountLimit > 0 && amountLimit === appliedDiscountAmount;
    const country = (locale || '').slice(-2);
    const noelShipping = exists(`checkout_override_shipping_${country}`) && (t(`checkout_override_shipping_${country}`) || '').length > 0;
    return (
        <div className={className}>
            <Accordion elevation={0} classes={{root: classes.root, expanded: classes.rootExpanded}} defaultExpanded={defaultExpanded}>
                <AccordionSummary
                    classes={{root: classes.summary, content: classes.summaryContent, expandIcon: classes.icon, expanded: classes.summaryExpanded}} expandIcon={<ExpandMoreIcon/>}>
                    <div className={classes.title}>{t('cart_amount_details')}</div>
                    <div className={classes.expandedTitle}>{t('cart_amount_hide_details')}</div>
                </AccordionSummary>
                <AccordionDetails classes={{root: classes.detailsRoot}}>
                    {subpriceDetails?.total && <div className={classes.line}>
                        <Trans
                            i18nKey='checkout_cart_header_ex_vat'
                            values={{amount: convertPrice(subpriceDetails.total, currency)}}
                            components={{1: <span className={classes.highlight} />}}
                        />
                    </div> || false}
                    {subpriceDetails?.taxTotal && <div className={classes.line}>
                        <Trans
                            i18nKey='checkout_cart_header_vat'
                            values={{amount: convertPrice(subpriceDetails.taxTotal, currency)}}
                            components={{1: <span className={classes.highlight} />}}
                        />
                    </div> || false}
                    {subpriceDetails?.totalWithVat && appliedDiscountAmount && <div className={clsx(classes.line, classes.lineParagraph)}>
                        <Trans
                            i18nKey='checkout_cart_header_total_without_reducton'
                            values={{amount: convertPrice(subpriceDetails.totalWithVat, currency)}}
                            components={{1: <span className={classes.highlight} />}}
                        />
                    </div> || false}
                    {appliedDiscountAmount && <div className={clsx(classes.line, classes.lineParagraph)}>
                        <Trans
                            i18nKey='checkout_cart_total_reduction'
                            values={{amount: `-${convertPrice(appliedDiscountAmount, currency)}${showAmountLimit ? '*' : ''}`}}
                            components={{1: <span className={classes.highlight}/>}}
                        />
                    </div> || false}
                    {discount && <div className={clsx(classes.line, classes.lineDiscount)}>
                        <Trans
                            i18nKey='amount_percent_cart'
                            values={{amount: `-${discount.value}`}}
                            components={{1: <span className={classes.highlight} />}}
                        />
                    </div> || false}
                    {productsDiscount && productsDiscount.map(productDiscount => (
                        <div className={clsx(classes.line, classes.lineDiscount)}>
                            <Trans
                                i18nKey='amount_percent_product'
                                values={{amount: `-${productDiscount.value}`, productName: productDiscount.name}}
                                components={{1: <span className={classes.highlight} />}}
                            />
                        </div>
                    )) || false}
                    {showAmountLimit && <div className={clsx(classes.line, classes.lineDiscountLimit)}>
                        <Trans
                            i18nKey='capped_discount'
                            values={{amount: convertPrice(amountLimit, currency)}}
                        />
                    </div> || false}
                    {shippingMethod && (
                        <div className={clsx(classes.line, classes.lineParagraph)}>
                            <Trans
                                i18nKey={noelShipping && `checkout_override_shipping_${country}` || 'checkout_cart_header_shipping_amount'}
                                values={{
                                    description: shippingMethod.description,
                                    amount: shippingMethod.price === 0 ? t('checkout_cart_total_free_shipping') : convertPrice(shippingMethod.price, currency)
                                }}
                                components={{1: <span className={classes.highlight} />}}
                            />
                        </div>
                    ) || false}
                    {accountBalance?.amount && <div className={clsx(classes.line, classes.lineParagraph)}>
                        <Trans
                            i18nKey='checkout_cart_header_total_account_balance'
                            values={{amount: `-${convertPrice(accountBalance.amount, currency)}`}}
                            components={{1: <span className={classes.highlight} />}}
                        />
                    </div> || false}
                    <div className={classes.total}>
                        <Trans
                            i18nKey='checkout_cart_header_total_amount'
                            values={{amount: convertPrice(totalPrice, currency)}}
                        />
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

export interface PaymentDetailsProps {
    cart: cart_type;
    className?: string;
    shippingMethod?: shipping_method|null;
    defaultExpanded?: boolean;
}

export default PaymentDetails;
